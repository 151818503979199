import React from 'react';
import PropTypes from 'prop-types';

import { Link as GatsbyLink } from 'gatsby';

import { OutboundLink } from 'gatsby-plugin-google-analytics';

export default function Link(props) {
    const { href, to, ...others } = props;

    if (to) {
        return <GatsbyLink {...others} to={to} />;
    } else {
        return <OutboundLink {...others} href={href} target="_blank" />;
    }
}

Link.propTypes = {
    href: PropTypes.string,
    to: PropTypes.string
};
