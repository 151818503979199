'use strict';

import React from 'react';
import styled from 'styled-components';
import Link from './Link';

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 4rem;
`;

const LogoContainer = styled.div`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: #ffffff;

    margin-left: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const RishitKediaLogo = styled.img`
    height: 2.25rem;
`;

const RishitKediaPhoto = styled.img`
    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    margin-left: 1rem;
`;

const StyledLi = styled.li`
    font-size: 1rem;

    display: inline-block;
    margin-left: 1rem;

    a {
        color: ${(props) =>
            props.selected ? props.theme.primaryColor : '#ffffff'};
    }

    a:hover {
        color: ${(props) => props.theme.primaryColor};
    }

    &:first-child {
        margin-left: 0;
    }
`;

export default ({ currentPage }) => {
    return (
        <header>
            <HeaderContainer>
                <StyledLi key="about" selected={currentPage === 'about'}>
                    <Link to={`/about`}>ABOUT</Link>
                </StyledLi>

                {currentPage === 'index' ? (
                    <Link to="/">
                        <LogoContainer>
                            <RishitKediaLogo
                                src={require('../assets/logo-rishit-kedia.svg')}
                                alt="Rishit Kedia"
                            />
                        </LogoContainer>
                    </Link>
                ) : (
                    <Link to="/">
                        <RishitKediaPhoto
                            src={require('../assets/photo-rishit-kedia.png')}
                            alt="Rishit Kedia"
                        />
                    </Link>
                )}

                <StyledLi key="contact" selected={currentPage === 'contact'}>
                    <Link to={`/contact`}>CONTACT</Link>
                </StyledLi>
            </HeaderContainer>
        </header>
    );
};
