'use strict';

import React from 'react';
import { Helmet } from 'react-helmet';

import styled, { ThemeProvider } from 'styled-components';

import Header from './Header';
import Footer from './Footer';

import 'typeface-poppins';
import 'typeface-montserrat';
import './index.css';

const theme = {
    primaryColor: '#ff5722'
};

const Container = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -200;

    overflow-y: auto;
    overflow-x: hidden;

    text-align: center;

    background-color: #000000;

    padding: 1.6rem;

    display: flex;
    flex-direction: column;
`;

const Main = styled.main`
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;

    flex: 1;
`;

export default ({ currentPage, children, data, className }) => (
    <ThemeProvider theme={theme}>
        <Container>
            <Helmet
                titleTemplate="%s - Rishit Kedia"
                defaultTitle="Rishit Kedia"
            >
                <meta
                    name="description"
                    content={data.site.siteMetadata.tagline}
                />

                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="/apple-touch-icon.png?v=2"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="/favicon-32x32.png?v=2"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="/favicon-16x16.png?v=2"
                />
                <link rel="manifest" href="/site.webmanifest?v=2" />
                <link
                    rel="mask-icon"
                    href="/safari-pinned-tab.svg?v=2"
                    color="#ff5722"
                />
                <link rel="shortcut icon" href="/favicon.ico?v=2" />
                <meta
                    name="apple-mobile-web-app-title"
                    content="Rishit Kedia"
                />
                <meta name="application-name" content="Rishit Kedia" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="theme-color" content="#ff5722" />
            </Helmet>

            <Header currentPage={currentPage} />

            <Main role="main" className={className}>
                {children}
            </Main>

            <Footer data={data.site} />
        </Container>
    </ThemeProvider>
);
