'use strict';

import React from 'react';
import styled from 'styled-components';
import Link from './Link';

import { graphql } from 'gatsby';

const FooterContainer = styled.footer`
    line-height: 1.25rem;
`;

const SmallText = styled.small`
    font-size: 0.9rem;
    
    a {
        color: #ffffff;
    }

    a:hover {
        color: ${props => props.theme.primaryColor};
    }
`;

const CopyrightText = styled(SmallText)``;

const COPYRIGHT_YEAR = new Date().getFullYear();

export default ({ data }) => (
    <FooterContainer>
        <SmallText>
            Made with utmost love and care by{' '}
            <Link to="/">
                {data.siteMetadata.name}
            </Link>{' '}
            in Dubai, United Arab Emirates.
        </SmallText>

        <br />
        
        <CopyrightText>
            Copyright &#169; {COPYRIGHT_YEAR}{' '}
            <Link to="/">{data.siteMetadata.name}</Link>. All rights reserved.
        </CopyrightText>
    </FooterContainer>
);

export const fragment = graphql`
    fragment FooterFragment on Site {
        siteMetadata {
            name
        }
    }
`;
